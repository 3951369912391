import * as React from 'react';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import {
  Box,
  Container,
  Link,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Footer from '../../components/Footer';

const StyledTableHead = styled(TableHead)(() => ({
  textAlign: 'center',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(() => ({
  textAlign: 'center',
  fontSize: '1rem',
}));

export default function RingSizing(): JSX.Element {
  return (
    <Layout title="Ring Sizing Guide | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="guides" />
        <Box flex={1} mb={2}>
          <Container>
            <Typography variant="h1" gutterBottom>Ring Sizing</Typography>
            <Typography variant="body1" gutterBottom>
              See our handy ring sizing conversion chart below.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you are unsure, our staff are more than happy to measure your ring
              size <Link href="/contact" underline="always">in store</Link> using a professional ring sizing tool.
            </Typography>
            <img
              src="/images/ring-sizing/diameter-and-circumference.png"
              alt="Diagram showing that diameter is the width of the ring and circumference is the length around it"
              css={{
                width: '120px',
                margin: '1em auto',
                display: 'block',
              }}
            />
          </Container>
          <Container sx={{overflowX: 'auto'}}>
            <TableContainer component={Paper} sx={{margin: '1rem 0', minWidth: '50rem'}}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Inside diameter</StyledTableCell>
                    <StyledTableCell>Inside circumference</StyledTableCell>
                    <StyledTableCell colSpan={4}>Sizing systems</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>mm</StyledTableCell>
                    <StyledTableCell>mm</StyledTableCell>
                    <StyledTableCell>
                      United Kingdom
                      <br />
                      Ireland
                      <br />
                      Australia
                      <br />
                      South Africa
                      <br />
                      New Zealand
                    </StyledTableCell>
                    <StyledTableCell>
                      United States
                      <br />
                      Canada
                      <br />
                      Mexico
                    </StyledTableCell>
                    <StyledTableCell>
                      China
                      <br />
                      Japan
                      <br />
                      South Korea
                      <br />
                      South America
                    </StyledTableCell>
                    <StyledTableCell>
                      Italy
                      <br />
                      Spain
                      <br />
                      Netherlands
                      <br />
                      Switzerland
                    </StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>11.63</StyledTableCell>
                    <StyledTableCell>36.5</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>0</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>11.84</StyledTableCell>
                    <StyledTableCell>37.2</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>12.04</StyledTableCell>
                    <StyledTableCell>37.8</StyledTableCell>
                    <StyledTableCell>A</StyledTableCell>
                    <StyledTableCell>&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>12.24</StyledTableCell>
                    <StyledTableCell>38.5</StyledTableCell>
                    <StyledTableCell>A&frac12;</StyledTableCell>
                    <StyledTableCell>&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>12.45</StyledTableCell>
                    <StyledTableCell>39.1</StyledTableCell>
                    <StyledTableCell>B</StyledTableCell>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>1</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>12.65</StyledTableCell>
                    <StyledTableCell>39.7</StyledTableCell>
                    <StyledTableCell>B&frac12;</StyledTableCell>
                    <StyledTableCell>1&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>12.85</StyledTableCell>
                    <StyledTableCell>40.4</StyledTableCell>
                    <StyledTableCell>C</StyledTableCell>
                    <StyledTableCell>1&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>0.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>13.06</StyledTableCell>
                    <StyledTableCell>41</StyledTableCell>
                    <StyledTableCell>C&frac12;</StyledTableCell>
                    <StyledTableCell>1&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>1</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>13.26</StyledTableCell>
                    <StyledTableCell>41.7</StyledTableCell>
                    <StyledTableCell>D</StyledTableCell>
                    <StyledTableCell>2</StyledTableCell>
                    <StyledTableCell>2</StyledTableCell>
                    <StyledTableCell>1.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>13.46</StyledTableCell>
                    <StyledTableCell>42.3</StyledTableCell>
                    <StyledTableCell>D&frac12;</StyledTableCell>
                    <StyledTableCell>2&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>2.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>13.67</StyledTableCell>
                    <StyledTableCell>42.9</StyledTableCell>
                    <StyledTableCell>E</StyledTableCell>
                    <StyledTableCell>2&frac12;</StyledTableCell>
                    <StyledTableCell>3</StyledTableCell>
                    <StyledTableCell>3</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>13.87</StyledTableCell>
                    <StyledTableCell>43.6</StyledTableCell>
                    <StyledTableCell>E&frac12;</StyledTableCell>
                    <StyledTableCell>2&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>3.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>14.07</StyledTableCell>
                    <StyledTableCell>44.2</StyledTableCell>
                    <StyledTableCell>F</StyledTableCell>
                    <StyledTableCell>3</StyledTableCell>
                    <StyledTableCell>4</StyledTableCell>
                    <StyledTableCell>4.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>14.27</StyledTableCell>
                    <StyledTableCell>44.8</StyledTableCell>
                    <StyledTableCell>F&frac12;</StyledTableCell>
                    <StyledTableCell>3&frac14;</StyledTableCell>
                    <StyledTableCell>5</StyledTableCell>
                    <StyledTableCell>4.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>14.48</StyledTableCell>
                    <StyledTableCell>45.5</StyledTableCell>
                    <StyledTableCell>G</StyledTableCell>
                    <StyledTableCell>3&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>5.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>14.68</StyledTableCell>
                    <StyledTableCell>46.1</StyledTableCell>
                    <StyledTableCell>G&frac12;</StyledTableCell>
                    <StyledTableCell>3&frac34;</StyledTableCell>
                    <StyledTableCell>6</StyledTableCell>
                    <StyledTableCell>6</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>14.88</StyledTableCell>
                    <StyledTableCell>46.8</StyledTableCell>
                    <StyledTableCell>H</StyledTableCell>
                    <StyledTableCell>4</StyledTableCell>
                    <StyledTableCell>7</StyledTableCell>
                    <StyledTableCell>6.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>15.09</StyledTableCell>
                    <StyledTableCell>47.4</StyledTableCell>
                    <StyledTableCell>H&frac12;</StyledTableCell>
                    <StyledTableCell>4&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>7.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>15.29</StyledTableCell>
                    <StyledTableCell>48</StyledTableCell>
                    <StyledTableCell>I</StyledTableCell>
                    <StyledTableCell>4&frac12;</StyledTableCell>
                    <StyledTableCell>8</StyledTableCell>
                    <StyledTableCell>8</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>15.49</StyledTableCell>
                    <StyledTableCell>48.7</StyledTableCell>
                    <StyledTableCell>J</StyledTableCell>
                    <StyledTableCell>4&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>8.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>15.7</StyledTableCell>
                    <StyledTableCell>49.3</StyledTableCell>
                    <StyledTableCell>J&frac12;</StyledTableCell>
                    <StyledTableCell>5</StyledTableCell>
                    <StyledTableCell>9</StyledTableCell>
                    <StyledTableCell>9.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>15.9</StyledTableCell>
                    <StyledTableCell>50</StyledTableCell>
                    <StyledTableCell>K</StyledTableCell>
                    <StyledTableCell>5&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>10</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>16.1</StyledTableCell>
                    <StyledTableCell>50.6</StyledTableCell>
                    <StyledTableCell>K&frac12;</StyledTableCell>
                    <StyledTableCell>5&frac12;</StyledTableCell>
                    <StyledTableCell>10</StyledTableCell>
                    <StyledTableCell>10.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>16.31</StyledTableCell>
                    <StyledTableCell>51.2</StyledTableCell>
                    <StyledTableCell>L</StyledTableCell>
                    <StyledTableCell>5&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>11.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>16.51</StyledTableCell>
                    <StyledTableCell>51.9</StyledTableCell>
                    <StyledTableCell>L&frac12;</StyledTableCell>
                    <StyledTableCell>6</StyledTableCell>
                    <StyledTableCell>11</StyledTableCell>
                    <StyledTableCell>11.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>16.71</StyledTableCell>
                    <StyledTableCell>52.5</StyledTableCell>
                    <StyledTableCell>M</StyledTableCell>
                    <StyledTableCell>6&frac14;</StyledTableCell>
                    <StyledTableCell>12</StyledTableCell>
                    <StyledTableCell>12.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>16.92</StyledTableCell>
                    <StyledTableCell>53.1</StyledTableCell>
                    <StyledTableCell>M&frac12;</StyledTableCell>
                    <StyledTableCell>6&frac12;</StyledTableCell>
                    <StyledTableCell>13</StyledTableCell>
                    <StyledTableCell>13.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>17.12</StyledTableCell>
                    <StyledTableCell>53.8</StyledTableCell>
                    <StyledTableCell>N</StyledTableCell>
                    <StyledTableCell>6&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>13.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>17.32</StyledTableCell>
                    <StyledTableCell>54.4</StyledTableCell>
                    <StyledTableCell>N&frac12;</StyledTableCell>
                    <StyledTableCell>7</StyledTableCell>
                    <StyledTableCell>14</StyledTableCell>
                    <StyledTableCell>14.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>17.53</StyledTableCell>
                    <StyledTableCell>55.1</StyledTableCell>
                    <StyledTableCell>O</StyledTableCell>
                    <StyledTableCell>7&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>15</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>17.73</StyledTableCell>
                    <StyledTableCell>55.7</StyledTableCell>
                    <StyledTableCell>O&frac12;</StyledTableCell>
                    <StyledTableCell>7&frac12;</StyledTableCell>
                    <StyledTableCell>15</StyledTableCell>
                    <StyledTableCell>15.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>17.93</StyledTableCell>
                    <StyledTableCell>56.3</StyledTableCell>
                    <StyledTableCell>P</StyledTableCell>
                    <StyledTableCell>7&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>16.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>18.14</StyledTableCell>
                    <StyledTableCell>57</StyledTableCell>
                    <StyledTableCell>P&frac12;</StyledTableCell>
                    <StyledTableCell>8</StyledTableCell>
                    <StyledTableCell>16</StyledTableCell>
                    <StyledTableCell>17</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>18.34</StyledTableCell>
                    <StyledTableCell>57.6</StyledTableCell>
                    <StyledTableCell>Q</StyledTableCell>
                    <StyledTableCell>8&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>17.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>18.54</StyledTableCell>
                    <StyledTableCell>58.3</StyledTableCell>
                    <StyledTableCell>Q&frac12;</StyledTableCell>
                    <StyledTableCell>8&frac12;</StyledTableCell>
                    <StyledTableCell>17</StyledTableCell>
                    <StyledTableCell>18.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>18.75</StyledTableCell>
                    <StyledTableCell>58.9</StyledTableCell>
                    <StyledTableCell>R</StyledTableCell>
                    <StyledTableCell>8&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>19</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>18.95</StyledTableCell>
                    <StyledTableCell>59.5</StyledTableCell>
                    <StyledTableCell>R&frac12;</StyledTableCell>
                    <StyledTableCell>9</StyledTableCell>
                    <StyledTableCell>18</StyledTableCell>
                    <StyledTableCell>19.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>19.15</StyledTableCell>
                    <StyledTableCell>60.2</StyledTableCell>
                    <StyledTableCell>S</StyledTableCell>
                    <StyledTableCell>9&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>20.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>19.35</StyledTableCell>
                    <StyledTableCell>60.8</StyledTableCell>
                    <StyledTableCell>S&frac12;</StyledTableCell>
                    <StyledTableCell>9&frac12;</StyledTableCell>
                    <StyledTableCell>19</StyledTableCell>
                    <StyledTableCell>20.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>19.56</StyledTableCell>
                    <StyledTableCell>61.4</StyledTableCell>
                    <StyledTableCell>T</StyledTableCell>
                    <StyledTableCell>9&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>21.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>19.76</StyledTableCell>
                    <StyledTableCell>62.1</StyledTableCell>
                    <StyledTableCell>T&frac12;</StyledTableCell>
                    <StyledTableCell>10</StyledTableCell>
                    <StyledTableCell>20</StyledTableCell>
                    <StyledTableCell>22</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>19.96</StyledTableCell>
                    <StyledTableCell>62.7</StyledTableCell>
                    <StyledTableCell>U</StyledTableCell>
                    <StyledTableCell>10&frac14;</StyledTableCell>
                    <StyledTableCell>21</StyledTableCell>
                    <StyledTableCell>22.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>20.17</StyledTableCell>
                    <StyledTableCell>63.4</StyledTableCell>
                    <StyledTableCell>U&frac12;</StyledTableCell>
                    <StyledTableCell>10&frac12;</StyledTableCell>
                    <StyledTableCell>22</StyledTableCell>
                    <StyledTableCell>23.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>20.37</StyledTableCell>
                    <StyledTableCell>64</StyledTableCell>
                    <StyledTableCell>V</StyledTableCell>
                    <StyledTableCell>10&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>24</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>20.57</StyledTableCell>
                    <StyledTableCell>64.6</StyledTableCell>
                    <StyledTableCell>V&frac12;</StyledTableCell>
                    <StyledTableCell>11</StyledTableCell>
                    <StyledTableCell>23</StyledTableCell>
                    <StyledTableCell>24.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>20.78</StyledTableCell>
                    <StyledTableCell>65.3</StyledTableCell>
                    <StyledTableCell>W</StyledTableCell>
                    <StyledTableCell>11&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>25.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>20.98</StyledTableCell>
                    <StyledTableCell>65.9</StyledTableCell>
                    <StyledTableCell>W&frac12;</StyledTableCell>
                    <StyledTableCell>11&frac12;</StyledTableCell>
                    <StyledTableCell>24</StyledTableCell>
                    <StyledTableCell>26</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>21.18</StyledTableCell>
                    <StyledTableCell>66.6</StyledTableCell>
                    <StyledTableCell>X</StyledTableCell>
                    <StyledTableCell>11&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>26.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>21.39</StyledTableCell>
                    <StyledTableCell>67.2</StyledTableCell>
                    <StyledTableCell>X&frac12;</StyledTableCell>
                    <StyledTableCell>12</StyledTableCell>
                    <StyledTableCell>25</StyledTableCell>
                    <StyledTableCell>27.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>21.59</StyledTableCell>
                    <StyledTableCell>67.8</StyledTableCell>
                    <StyledTableCell>Y</StyledTableCell>
                    <StyledTableCell>12&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>27.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>21.79</StyledTableCell>
                    <StyledTableCell>68.5</StyledTableCell>
                    <StyledTableCell>Z</StyledTableCell>
                    <StyledTableCell>12&frac12;</StyledTableCell>
                    <StyledTableCell>26</StyledTableCell>
                    <StyledTableCell>28.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>22</StyledTableCell>
                    <StyledTableCell>69.1</StyledTableCell>
                    <StyledTableCell>Z&frac12;</StyledTableCell>
                    <StyledTableCell>12&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>29</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>22.2</StyledTableCell>
                    <StyledTableCell>69.7</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>13</StyledTableCell>
                    <StyledTableCell>27</StyledTableCell>
                    <StyledTableCell>29.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>22.4</StyledTableCell>
                    <StyledTableCell>70.4</StyledTableCell>
                    <StyledTableCell>Z1</StyledTableCell>
                    <StyledTableCell>13&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>30.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>22.61</StyledTableCell>
                    <StyledTableCell>71</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>13&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>31</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>22.81</StyledTableCell>
                    <StyledTableCell>71.7</StyledTableCell>
                    <StyledTableCell>Z2</StyledTableCell>
                    <StyledTableCell>13&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>31.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>23.01</StyledTableCell>
                    <StyledTableCell>72.3</StyledTableCell>
                    <StyledTableCell>Z3</StyledTableCell>
                    <StyledTableCell>14</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>32.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>23.22</StyledTableCell>
                    <StyledTableCell>72.9</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>14&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>33</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>23.42</StyledTableCell>
                    <StyledTableCell>73.6</StyledTableCell>
                    <StyledTableCell>Z4</StyledTableCell>
                    <StyledTableCell>14&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>33.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>23.62</StyledTableCell>
                    <StyledTableCell>74.2</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>14&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>34.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>23.83</StyledTableCell>
                    <StyledTableCell>74.8</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>15</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>34.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>24.03</StyledTableCell>
                    <StyledTableCell>75.5</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>15&frac14;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>35.5</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>24.23</StyledTableCell>
                    <StyledTableCell>76.1</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>15&frac12;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>36.25</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>24.43</StyledTableCell>
                    <StyledTableCell>76.8</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>15&frac34;</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>36.75</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>24.64</StyledTableCell>
                    <StyledTableCell>77.4</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>16</StyledTableCell>
                    <StyledTableCell>&nbsp;</StyledTableCell>
                    <StyledTableCell>37.5</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}
