import * as React from 'react';
import { Facebook, Instagram } from '@mui/icons-material';
import { Box, Breakpoint, Container, Grid, Link, Stack, Typography } from '@mui/material';

type FooterProps = {
  maxWidth?: Breakpoint | string | false;
};

export default function Footer({ maxWidth }: FooterProps): JSX.Element {
  const [email, setEmail] = React.useState({isSet: false, address: '---'});

  React.useEffect(() => {
    const obfEmail = ['ocleeandson.co.uk', '@', 'contact'];
    setEmail({isSet: true, address: obfEmail.reverse().join('')});
  }, []);

  return (
    <Box bgcolor="#fff5f2">
      <Container
        maxWidth={typeof(maxWidth) === 'string' ? false : maxWidth}
        sx={typeof(maxWidth) === 'string' ? { maxWidth } : {}}
      >
        <Stack direction="column" gap={1} my={3}>
          <Grid container>
            <Grid item xs={8}>
              <Typography mb={1}>Oclee &amp; Son &copy; { new Date().getFullYear() }</Typography>
              <Typography>01303 252654</Typography>
              <Typography color={email.isSet ? 'text.primary' : 'transparent'}>{email.address}</Typography>
              <Typography display="none" sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  display: 'block',
                },
              })}>
                35 Sandgate Road, Folkestone, Kent, CT20 1SB
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Link href="https://www.facebook.com/ocleeandson/">
                <Facebook fontSize="large" />
              </Link>
              <Link href="https://www.instagram.com/oclee_and_son/">
                <Instagram fontSize="large" />
              </Link>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
